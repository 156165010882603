import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FilterService } from "../_services/filter.service";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { filter } from "lodash";

const FilterDropdownButton = forwardRef(
  ({ listName, filterValue, onSetFilter }, ref) => {
    const [filterList, setFilterList] = useState([]);
    const [activeFilter, setActiveFilter] = useState(null);

    useEffect(() => {
      FilterService.getByList(listName).then((filters) => {
        setFilterList(filters);
      });
    }, [listName]);

    const filterRef = useRef(null);

    useImperativeHandle(
      ref,
      () => {
        return {
          clearFilter() {
            filterRef.current.clear();
            setActiveFilter(null);
          },
        };
      },
      []
    );

    const handleChangeFilter = (filter) => {
      if (filter.customOption) {
        if (
          // eslint-disable-next-line no-restricted-globals
          confirm(
            `Sollen die Filtereinstellungen als '${filter.filterName}' gespeichert werden?`
          )
        ) {
          FilterService.create({
            filterName: filter.filterName,
            filterSettings: JSON.stringify(filterValue),
            list: listName,
          }).then((savedFilter) => {
            setFilterList((oldList) => {
              const newList = [...oldList];
              newList.push(savedFilter);
              return newList;
            });
            filterRef.current.hideMenu();
          });
        } else {
          setActiveFilter(null);
          filterRef.current.hideMenu();
          filterRef.current.blur();
          filterRef.current.clear();
        }
      } else {
        onSetFilter(JSON.parse(filter.filterSettings));
      }
    };

    const handleDeleteFilter = () => {
      if (!activeFilter) return;
      if (activeFilter.customOption) return;
      // eslint-disable-next-line no-restricted-globals
      if (!confirm(`Soll Filter '${activeFilter.filterName}' gelöscht werden?`))
        return;
      FilterService.delete(activeFilter.id).then(() => {
        setFilterList((oldList) => {
          return filter(oldList, (item) => item.id !== activeFilter.id);
        });
        setActiveFilter(null);
        filterRef.current.clear();
      });
    };

    const handleSaveFilter = () => {
      if (!activeFilter) return;
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(
          `Sollen die Filtereinstellungen als '${filter.filterName}' gespeichert werden?`
        )
      )
        return;
      if (activeFilter.customOption) {
        FilterService.create({
          filterName: activeFilter.filterName,
          filterSettings: JSON.stringify(filterValue),
          list: listName,
        }).then((savedFilter) => {
          setFilterList((oldList) => {
            const newList = [...oldList];
            newList.push(savedFilter);
            return newList;
          });
        });
      } else {
        FilterService.update(activeFilter.id, {
          id: activeFilter.id,
          filterName: activeFilter.filterName,
          filterSettings: JSON.stringify(filterValue),
          list: listName,
        }).then((savedFilter) => {
          setFilterList((oldList) => {
            const newList = filter(
              oldList,
              (item) => item.id !== savedFilter.id
            );
            newList.push(savedFilter);
            return newList;
          });
        });
      }
    };

    return (
      <>
        <button
          type="button"
          id="filterDropdown"
          className="btn btn-sm btn-secondary dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          Meine Filter
        </button>
        <div className="dropdown-menu p-2" style={{ width: 350 }}>
          <div className="constainer">
            <div className="row justify-content-between">
              <div className="col">
                <Typeahead
                  id="filterTypeahead"
                  allowNew
                  clearButton={true}
                  labelKey={"filterName"}
                  newSelectionPrefix="Neuen Filter anlegen: "
                  options={filterList}
                  placeholder="Filter auswählen oder neuen anlegen..."
                  ref={filterRef}
                  onChange={(selectedFilters) => {
                    if (selectedFilters.length > 0) {
                      const filter = selectedFilters[0];
                      setActiveFilter(filter);
                      handleChangeFilter(filter);
                    } else setActiveFilter(null);
                  }}
                />
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-3">
                <button
                  className="btn btn-sm btn-primary m-2"
                  onClick={handleSaveFilter}
                  disabled={!activeFilter}
                >
                  Speichern
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-sm btn-danger m-2"
                  onClick={handleDeleteFilter}
                  disabled={!activeFilter || activeFilter.customOption}
                >
                  Löschen
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default FilterDropdownButton;
