import React from "react";
import { fundingService } from "../../_services/funding.service";
import { selectService } from "../../_services";
import BaseList, { dateColumnProps } from "../../_components/BaseList";
import SelectFilter from "@inovua/reactdatagrid-enterprise/SelectFilter";
import {
  renderEuro,
  renderFloat,
  renderPercent,
} from "../../_components/Form/Utils";
import { get } from "lodash";
import { Role } from "../../_helpers";
import { render } from "@testing-library/react";

function List({ match }) {
  const getSelectValues = (selectField) => {
    return get(selectService, `value.${selectField}`, []);
  };
  const columns = [
    {
      name: "id",
      header: "Id",
      defaultVisible: false,
      visible: false,
      hideable: false,
    },
    {
      name: "idFunding",
      header: "ID Funding",
      notEmpty: true,
      editable: false,
    },
    {
      name: "projectStatus",
      header: "Project Status",
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Project Status filtern...",
        dataSource: getSelectValues("fundingProjectStatus").map((c) => {
          return { id: c, label: c };
        }),
      },
    },
    { name: "call", header: "Call" },
    { name: "sign", header: "Sign" },
    {
      name: "budget",
      header: "Budget",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "quota",
      header: "Quota",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderPercent,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "start",
      header: "Start",
      ...dateColumnProps,
    },
    {
      name: "end",
      header: "End",
      ...dateColumnProps,
    },
    { name: "contact1", header: "Contact1" },
    { name: "contact2", header: "Contact2" },
    { name: "contact3", header: "Contact3" },
    {
      name: "appropriation",
      header: "Appropriation",
    },
    {
      name: "cashflow",
      header: "Cashflow",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "calculatedBudget",
      header: "Calculated Budget",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      editable: false,
      render: renderEuro,
    },
    {
      name: "comment",
      header: "Comment",
    },
    {
      name: "lastUpdateDate",
      header: "Last Update Date",
      ...dateColumnProps,
      dateFormat: "DD.MM.YYYY hh:mm",
      editable: false,
    },
    {
      name: "lastUpdateUser",
      header: "Last Update User",
      editable: false,
    },
  ];

  const filterValue = [
    { name: "idFunding", operator: "contains", type: "string", value: "" },
    { name: "street", operator: "contains", type: "string", value: "" },
    { name: "city", operator: "contains", type: "string", value: "" },
    { name: "projectStatus", operator: "inlist", type: "select" },
    { name: "call", operator: "contains", type: "string", value: "" },
    { name: "sign", operator: "contains", type: "string", value: "" },
    { name: "budget", type: "number" },
    { name: "quota", type: "number" },
    { name: "start", operator: "after", type: "date", value: "" },
    { name: "end", operator: "after", type: "date", value: "" },
    { name: "contact1", operator: "contains", type: "string", value: "" },
    { name: "contact2", operator: "contains", type: "string", value: "" },
    { name: "contact3", operator: "contains", type: "string", value: "" },
    { name: "appropriation", operator: "contains", type: "string", value: "" },
    {
      name: "appropriationDeadline",
      operator: "after",
      type: "date",
      value: "",
    },
    { name: "comment", operator: "contains", type: "string", value: "" },

    { name: "cashflow", type: "number" },
    { name: "calculatedBudget", type: "number" },
    { name: "lastUpdateDate", operator: "after", type: "date", value: "" },
    { name: "lastUpdateUser", operator: "contains", type: "string", value: "" },
  ];

  return (
    <BaseList
      columns={columns}
      match={match}
      filterValue={filterValue}
      service={fundingService}
      storageKey="funding"
      title="Funding"
      enableAdd
      enableExport
      entityName="Funding"
      idColumn="idProject"
      roles={[Role.Admin, Role.Controller, Role.FM]}
    />
  );
}

export { List };
