import React, { useState } from "react";
import { ErrorMessage, Field } from "formik";
import { PropTypes } from "prop-types";
import CreatableSelect from "react-select/creatable";
import { map } from "lodash";
import Select from "react-select";

export function SelectField({
  className,
  creatable,
  errors,
  touched,
  fieldName,
  fieldLabel,
  values,
  onCreateOption,
  ...props
}) {
  const [options, setOptions] = useState(
    map(values, (val) => ({ value: val, label: val }))
  );
  const handleCreate = (newOption, setFieldValue) => {
    setOptions((prev) => [...prev, { value: newOption, label: newOption }]);
    setFieldValue(fieldName, newOption);
    if (onCreateOption) onCreateOption(newOption);
  };

  const creatableInput = ({
    field,
    form: { touched, errors, setFieldValue },
    ...props
  }) => (
    <CreatableSelect
      isClearable
      className={
        "form-control" +
        (errors[field.name] && touched[field.name] ? " is-invalid" : "")
      }
      onChange={(option) =>
        option
          ? setFieldValue(field.name, option.value)
          : setFieldValue(field.name, "")
      }
      onBlur={field.onBlur}
      onCreateOption={(option) => handleCreate(option, setFieldValue)}
      options={options}
      value={
        options
          ? options.find((option) => option.value === field.value)
          : undefined
      }
      {...props}
    />
  );

  const input = ({
    field,
    form: { touched, errors, setFieldValue },
    ...props
  }) => (
    <Select
      isClearable
      className={
        "form-control" +
        (errors[field.name] && touched[field.name] ? " is-invalid" : "")
      }
      onChange={(option) =>
        option
          ? setFieldValue(field.name, option.value)
          : setFieldValue(field.name, "")
      }
      onBlur={field.onBlur}
      options={options}
      value={
        options
          ? options.find((option) => option.value === field.value)
          : undefined
      }
      {...props}
    />
  );

  return (
    <div className={className || "form-group col"}>
      <label>{fieldLabel}</label>
      <Field
        name={fieldName}
        component={creatable ? creatableInput : input}
        type="text"
        {...props}
      />
      <ErrorMessage
        name={fieldName}
        component="div"
        className="invalid-feedback"
      />
    </div>
  );
}

SelectField.propTypes = {
  className: PropTypes.string,
  creatable: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  onCreateOption: PropTypes.func,
};

SelectField.defaultProps = {
  onCreateOption: null,
  creatable: true,
};
