import React from "react";
import { Router } from "react-router-dom";
import { createRoot } from "react-dom/client";

import { history } from "./_helpers";
import { accountService } from "./_services";
import { App } from "./app";

import "./styles.less";
import "react-datetime/css/react-datetime.css";

// setup fake backend
// import { configureFakeBackend } from './_helpers';
// configureFakeBackend();

// attempt silent token refresh before startup
accountService.refreshToken().finally(startApp);

function startApp() {
  const root = createRoot(document.getElementById("app"));
  root.render(
    <Router history={history}>
      <App />
    </Router>
  );
}
