import React, { useEffect, useState } from "react";
import BaseGrid from "../../_components/BaseGrid";
import { projectService } from "../../_services/project.service";
import { dateColumnProps } from "../../_components/BaseList";

const ProjectList = ({ idSite, onSelectProject }) => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (idSite) {
      projectService.getByIdSite(idSite).then((projectsData) => {
        setProjects(projectsData);
      });
    }
  }, [idSite]);

  const columns = [
    {
      name: "id",
      header: "Id",
      visible: false,
    },
    {
      name: "idProject",
      header: "No.",
    },
    {
      name: "projectType",
      header: "Project Title",
    },
    {
      name: "projectStatus",
      header: "Status",
    },
    {
      name: "count350bar",
      header: "350 bar",
    },
    {
      name: "count700bar",
      header: "700 bar",
    },
    {
      name: "projectAddCapacity",
      header: "add. Capacity [kg/d]",
      minWidth: 180,
    },
    {
      name: "projectGoliveDate",
      header: "GoLive Date",
      ...dateColumnProps,
    },
  ];

  const onRowClick = (rowProps, event) => {
    let project = projects[rowProps.rowIndex];
    if (project) {
      onSelectProject(project);
    }
  };

  return (
    <div id="projectList">
      <BaseGrid
        columns={columns}
        dataSource={projects}
        showColumnMenuTool={false}
        onRowClick={onRowClick}
        showZebraRows
      ></BaseGrid>
    </div>
  );
};

export default ProjectList;
