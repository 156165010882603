import { createService } from "./base.service";

export * from "./account.service";
export * from "./alert.service";
export * from "./select.service";
export * from "./erp.service";
export * from "./project.service";
export * from "./funding.service";
export * from "./operation.service";
export * from "./capex.service";
export * from "./ubp.service";

export const fundingService = createService(
  `${process.env.REACT_APP_API_SERVER}/fundings`
);
export const idService = createService(
  `${process.env.REACT_APP_API_SERVER}/ids`
);
export const siteService = createService(
  `${process.env.REACT_APP_API_SERVER}/sites`
);
