import React from "react";
import { ErrorMessage, Field } from "formik";
import { PropTypes } from "prop-types";

export function BaseField({
  className,
  errors,
  touched,
  fieldName,
  fieldLabel,
  type,
  ...props
}) {
  return (
    <div className={className || "form-group col"}>
      <label>{fieldLabel}</label>
      <Field
        name={fieldName}
        type={type}
        className={
          "form-control" +
          (errors[fieldName] && touched[fieldName] ? " is-invalid" : "")
        }
        {...props}
      />
      <ErrorMessage
        name={fieldName}
        component="div"
        className="invalid-feedback"
      />
    </div>
  );
}

BaseField.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  fieldName: PropTypes.string,
  fieldLabel: PropTypes.string,
  type: PropTypes.string,
};

BaseField.defaultProps = {
  type: "text",
};
