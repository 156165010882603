import { filter } from "lodash";
import { createService } from "./base.service";
import { fetchWrapper } from "../_helpers";

export const erpService = (() => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER}/erps`;
  const service = createService(baseUrl);
  const getByIdSite = function (idSite) {
    if (!service.initialLoad) {
      return fetchWrapper.get(`${baseUrl}/idSite/${idSite}`);
    } else {
      const items = service.value;
      return Promise.resolve(filter(items, { idSite: idSite }));
    }
  };
  return {
    ...service,
    getByIdSite,
  };
})();
