import React from "react";

import { operationService } from "../../_services";
import BaseList, { dateColumnProps } from "../../_components/BaseList";
import {
  renderFloat,
  renderInt,
  renderKilo,
  renderPercent,
} from "../../_components/Form/Utils";
import { Role } from "../../_helpers";

function List({ match }) {
  const columns = [
    {
      name: "id",
      header: "Id",
      defaultVisible: false,
      visible: false,
      hideable: false,
    },
    {
      name: "idSite",
      header: "ID Site",
      minWidth: 80,
      notEmpty: true,
      editable: false,
    },
    {
      name: "street",
      header: "Street",
      defaultFlex: 1,
      minWidth: 80,
      editable: false,
    },
    {
      name: "city",
      header: "City",
      defaultFlex: 1,
      minWidth: 80,
      editable: false,
    },
    {
      name: "idOperation",
      header: "ID_operationx",
      minWidth: 80,
    },
    {
      name: "operationDate",
      header: "Date",
      minWidth: 80,

      ...dateColumnProps,
    },
    {
      name: "sales700bar",
      header: "Sales 700bar",
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderKilo,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "availability700bar",
      header: "Availability 700bar",
      minWidth: 80,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderPercent,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "performance700bar",
      header: "Performance 700bar",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      minWidth: 80,
      render: renderPercent,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "successRefuelings700bar",
      header: "Successful Refuelings 700bar",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      minWidth: 80,
      render: renderInt,
      getEditStartValue: (value) => renderInt({ value }),
    },
    {
      name: "attempts700bar",
      header: "Attempts 700bar",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderInt,
      getEditStartValue: (value) => renderInt({ value }),
    },
    {
      name: "sales350bar",
      header: "Sales 350bar",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      minWidth: 80,
      render: renderKilo,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "availability350bar",
      header: "Availability 350bar",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      minWidth: 80,
      render: renderPercent,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "performance350bar",
      header: "Performance 350bar",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      minWidth: 80,
      render: renderPercent,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "successRefuelings350bar",
      header: "Successful Refuelings 350bar",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      minWidth: 80,
      render: renderInt,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "attempts350bar",
      header: "Attempts 350bar",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      minWidth: 80,
      defaultFlex: 1,
      render: renderInt,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "load",
      header: "Load",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      minWidth: 80,
      render: renderPercent,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "salesTotal",
      header: "Sales Total",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      minWidth: 80,
      render: renderKilo,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "salesShare350bar",
      header: "Sales Share 350bar",
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      minWidth: 80,
      render: renderPercent,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    { name: "comment", header: "Comment", defaultFlex: 1 },
    {
      name: "lastUpdateDate",
      header: "Last Update Date",
      ...dateColumnProps,
      dateFormat: "DD.MM.YYYY hh:mm",
      editable: false,
    },
    {
      name: "lastUpdateUser",
      header: "Last Update User",
      editable: false,
    },
  ];

  const filterValue = [
    { name: "idOperation", operator: "contains", type: "string", value: "" },
    { name: "idSite", operator: "contains", type: "string", value: "" },
    { name: "street", operator: "contains", type: "string", value: "" },
    { name: "city", operator: "contains", type: "string", value: "" },
    { name: "operationDate", operator: "after", type: "date", value: "" },
    { name: "sales700bar", operator: "contains", type: "string", value: "" },
    {
      name: "availability700bar",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "performance700bar",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "successRefuelings700bar",
      operator: "contains",
      type: "string",
      value: "",
    },
    { name: "attempts700bar", operator: "contains", type: "string", value: "" },
    { name: "sales350bar", operator: "contains", type: "string", value: "" },
    {
      name: "availability350bar",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "performance350bar",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "successRefuelings350bar",
      operator: "contains",
      type: "string",
      value: "",
    },
    { name: "attempts350bar", operator: "contains", type: "string", value: "" },
    { name: "load", operator: "contains", type: "string", value: "" },
    { name: "salesTotal", operator: "contains", type: "string", value: "" },
    {
      name: "salesShare350bar",
      operator: "contains",
      type: "string",
      value: "",
    },
    { name: "comment", operator: "contains", type: "string", value: "" },
    { name: "lastUpdateDate", operator: "after", type: "date", value: "" },
    {
      name: "lastUpdateUser",
      operator: "contains",
      type: "string",
      value: "",
    },
  ];

  return (
    <BaseList
      columns={columns}
      match={match}
      filterValue={filterValue}
      service={operationService}
      storageKey="operations"
      title="Operation KPIs"
      enableExport
      enableImport
      enableAdd
      entityName="Operation KPI"
      idColumn="idOperation"
      roles={[Role.Admin, Role.Controller]}
    />
  );
}

export { List };
