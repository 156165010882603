import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { alertService } from "../_services";

function FileUpload({ title, uploadFile, returnRoute }) {
  const history = useHistory();

  const [selectedFile, setSelectedFile] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const onFileChange = (event) => {
    const file = event.target.files[0];

    if (
      file &&
      file.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      alertService.error("Wrong type only *.csv allowed.");
      return;
    }
    setSelectedFile(file);
  };

  const onStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const onFileUpload = () => {
    setSubmitting(true);

    uploadFile(selectedFile, startDate)
      .then(() => {
        alertService.success("Upload erfolgreich", {
          keepAfterRouteChange: true,
        });
        history.push(returnRoute);
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  };

  return (
    <>
      <h2>{title} Import File</h2>
      <div className="container-fluid">
        <div className="row justify-content-start">
          <div className="col-sm ">
            <input
              className="form-control-file"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={onFileChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm ">
            <label className="form-label" style={{ marginTop: "10px" }}>
              Import from Date:
            </label>
            <input
              className="form-control"
              style={{ maxWidth: "150px" }}
              type="date"
              onChange={onStartDateChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <button
              style={{ marginTop: "10px" }}
              type="submit"
              className="btn btn-primary"
              onClick={onFileUpload}
              disabled={!(selectedFile && startDate) || submitting}
            >
              Import
            </button>
            {submitting && (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Submitting File...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default FileUpload;
