import React, { useEffect, useState } from "react";
import Select from "react-select";
import { siteService } from "../../_services";
import { defaultTo, map, orderBy } from "lodash";
import SiteDashboard from "./SiteDashboard";

const Dashboard = () => {
  const [siteId, setSiteId] = useState(0);
  const [siteOptions, setSiteOptions] = useState([]);

  useEffect(() => {
    siteService.load().then((data) => {
      const orderedData = orderBy(data, ["city", "street"]);
      setSiteOptions(
        map(orderedData, (site) => ({
          value: site.id,
          label: `${site.idSite} ${site.city} ${defaultTo(site.street, "")}${
            site.status ? " (" + site.status + ")" : ""
          }`,
        }))
      );
    });
  }, []);

  return (
    <div className="p-4">
      <div id="dashboard" className="container-fluid">
        <div className="row" style={{ marginBottom: "10px" }}>
          <div className="col-12">
            <Select
              autoFocus
              onChange={(item) => {
                setSiteId(item.value);
              }}
              options={siteOptions}
              placeholder="Site auswählen..."
            ></Select>
          </div>
        </div>
        <div className="row">{siteId > 0 && <SiteDashboard id={siteId} />}</div>
      </div>
    </div>
  );
};

export default Dashboard;
