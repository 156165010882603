import React from "react";

import { selectService, siteService } from "../../_services";
import BaseList, { dateColumnProps } from "../../_components/BaseList";
import SelectFilter from "@inovua/reactdatagrid-enterprise/SelectFilter";
import { get } from "lodash";
import { Role } from "../../_helpers";

function List({ match }) {
  const getSelectValues = (selectField) => {
    return get(selectService, `value.${selectField}`, []);
  };

  const columns = [
    {
      name: "id",
      header: "Id",
      defaultFlex: 1,
      defaultdefaultVisible: false,
      visible: false,
      defaultVisible: false,
      hideable: false,
    },
    {
      name: "idSite",
      header: "ID Site",
      defaultFlex: 1,
      minWidth: 80,
      notEmpty: true,
      unique: true,
      editable: false,
    },
    {
      name: "zip",
      header: "Zip",
      defaultFlex: 1,
      minWidth: 80,
    },
    { name: "city", header: "City", defaultFlex: 1, minWidth: 80 },
    { name: "street", header: "Street", defaultFlex: 1, minWidth: 80 },
    {
      name: "federalState",
      header: "Federal State",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "firstGoLiveDate",
      header: "First GoLive Date",
      ...dateColumnProps,
    },
    {
      name: "closingDate",
      header: "Closing Date",
      ...dateColumnProps,
    },
    {
      name: "status",
      header: "Status",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "Status filtern...",
        dataSource: getSelectValues("siteStatus").map((c) => {
          return { id: c, label: c };
        }),
      },
    },
    {
      name: "ioc",
      header: "IOC",
      defaultFlex: 1,
      minWidth: 80,
      filterEditor: SelectFilter,
      filterEditorProps: {
        multiple: true,
        wrapMultiple: false,
        placeholder: "IOC filtern...",
        dataSource: getSelectValues("siteIOCs").map((c) => {
          return { id: c, label: c };
        }),
      },
    },
    { name: "ownerHRS", header: "Owner HRS", defaultFlex: 1, minWidth: 80 },
    { name: "contractor", header: "Contractor", defaultFlex: 1, minWidth: 80 },
    {
      name: "retailAgreementEnd",
      header: "Retail Agreement End",
      ...dateColumnProps,
    },
    {
      name: "h2SupplierGrey",
      header: "H2 Supplier Grey",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "h2SupplierGreen",
      header: "H2 Supplier Green",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "h2SupplyGreenStart",
      header: "H2 Supply Green Start",
      defaultFlex: 1,
      minWidth: 80,
      ...dateColumnProps,
    },
    {
      name: "statusOfSupply",
      header: "Status of Supply",
      defaultFlex: 1,
      minWidth: 80,
    },
    {
      name: "networkType",
      header: "Network Type",
      defaultFlex: 1,
      minWidth: 80,
    },
    { name: "comment", header: "Comment", defaultFlex: 1, minWidth: 80 },
    {
      name: "lastUpdateDate",
      header: "Last Update Date",
      defaultFlex: 1,
      minWidth: 80,
      ...dateColumnProps,
      dateFormat: "DD.MM.YYYY hh:mm",
      editable: false,
    },
    {
      name: "lastUpdateUser",
      header: "Last Update User",
      defaultFlex: 1,
      minWidth: 80,
      editable: false,
    },
  ];

  const filterValue = [
    { name: "idSite", operator: "contains", type: "string", value: "" },
    {
      name: "zip",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "city",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "street",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "federalState",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "firstGoLiveDate",
      operator: "after",
      type: "date",
      value: "",
    },
    { name: "closingDate", operator: "after", type: "date", value: "" },
    {
      name: "status",
      type: "select",
      operator: "inlist",
      active: true,
    },
    { name: "ioc", type: "select", operator: "inlist", active: true },
    { name: "ownerHRS", operator: "contains", type: "string", value: "" },
    { name: "contractor", operator: "contains", type: "string", value: "" },

    {
      name: "retailAgreementEnd",
      operator: "after",
      type: "date",
      value: "",
    },
    { name: "h2SupplierGrey", operator: "contains", type: "string", value: "" },
    {
      name: "h2SupplierGreen",
      operator: "contains",
      type: "string",
      value: "",
    },
    {
      name: "h2SupplyGreenStart",
      operator: "after",
      type: "date",
      value: "",
    },
    { name: "statusOfSupply", operator: "contains", type: "string", value: "" },
    { name: "networkType", operator: "contains", type: "string", value: "" },
    { name: "lastUpdateDate", operator: "after", type: "date", value: "" },
    { name: "lastUpdateUser", operator: "contains", type: "string", value: "" },
    { name: "comment", operator: "contains", type: "string", value: "" },
  ];

  return (
    <BaseList
      columns={columns}
      match={match}
      filterValue={filterValue}
      service={siteService}
      storageKey="sites"
      title="Sites"
      enableAdd
      enableExport
      idColumn="idSite"
      entityName="Site"
      roles={[Role.Admin, Role.Controller, Role.PM_PD]}
    />
  );
}

export { List };
