import React, { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import moment from "moment";
import Datetime from "react-datetime";

function formatDate(momentDate) {
  return moment(momentDate).format("YYYY-MM-DD");
}

export function DateField({
  errors,
  touched,
  values,
  setFieldValue,
  fieldName,
  fieldLabel,
}) {
  const [dateValue, setDateValue] = useState(moment(values[fieldName]));

  const fieldValue = values[fieldName];
  useEffect(() => {
    if (fieldValue !== formatDate(dateValue)) {
      setDateValue(moment(fieldValue));
    }
  }, [fieldValue]);

  return (
    <div className="form-group col">
      <label>{fieldLabel}</label>
      <Field
        className={
          "form-control" +
          (errors[fieldName] && touched[fieldName] ? " is-invalid" : "")
        }
        name={fieldName}
        id={fieldName}
        type="date"
      >
        {() => (
          <Datetime
            value={dateValue}
            timeFormat={false}
            dateFormat={"DD.MM.YYYY"}
            onChange={(val) => {
              setDateValue(val);
              if (val._isAMomentObject) {
                setFieldValue(fieldName, formatDate(val));
              } else if (val === "") {
                setFieldValue(fieldName, null);
              }
            }}
          />
        )}
      </Field>
      <ErrorMessage
        name={fieldName}
        component="div"
        className="invalid-feedback"
      />
    </div>
  );
}
