import React, { useEffect, useState } from "react";
import { operationService } from "../../_services";
import { toDateString, toKilogram, toPercent } from "./../../_helpers/utils";
import { orderBy } from "lodash";

const OperationDashboard = ({ idSite }) => {
  const [operations, setOperations] = useState([]);

  useEffect(() => {
    operationService
      .getByIdSite(idSite)
      .then((operationData) =>
        setOperations(orderBy(operationData, "operationDate"))
      );
  }, [idSite]);

  return (
    <div className="tableWrap">
      <table className="kpi">
        <thead>
          <tr>
            <th colSpan="14">
              <h4 style={{ marginBottom: "0" }}>Operational KPIs</h4>
            </th>
          </tr>
          <tr>
            <th></th>
            <th colSpan="4">Sales (kg)</th>
            <th>Load</th>
            <th colSpan="2">Availability</th>
            <th colSpan="3">Performance 700 bar</th>
            <th colSpan="3">Performance 350 bar</th>
          </tr>
          <tr>
            <th>Date</th>
            <th>350 bar</th>
            <th>700 bar</th>
            <th>Total</th>
            <th>Share 350</th>
            <th>tech. cap.</th>
            <th>700 bar</th>
            <th>350 bar</th>
            <th>Attem.</th>
            <th>Succful</th>
            <th>Perf.</th>
            <th>Attem.</th>
            <th>Succful</th>
            <th>Perf.</th>
          </tr>
        </thead>
        <tbody>
          {operations.map((operation) => {
            return (
              <tr key={operation.operationDate}>
                <td>
                  {toDateString(operation.operationDate, {
                    month: "short",
                    year: "numeric",
                  })}
                </td>
                <td>{toKilogram(operation.sales350bar, 0)}</td>
                <td>{toKilogram(operation.sales700bar, 0)}</td>
                <td>{toKilogram(operation.salesTotal, 0)}</td>
                <td>
                  {toPercent(operation.salesShare350bar, {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {toPercent(operation.load, {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {toPercent(operation.availability700bar, {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {toPercent(operation.availability350bar, {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>{operation.attempts700bar}</td>
                <td>{operation.successRefuelings700bar}</td>
                <td>
                  {toPercent(operation.performance700bar, {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>{operation.attempts350bar}</td>
                <td>{operation.successRefuelings350bar}</td>
                <td>
                  {toPercent(operation.performance350bar, {
                    maximumFractionDigits: 0,
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OperationDashboard;
