import React from "react";
import { Route, Switch } from "react-router-dom";

import { List } from "./List";
import { AddEdit } from "./AddEdit";
import { PrivateRoute } from "../../_components";
import { Role } from "../../_helpers";
import FileUpload from "../../_components/FileUpload";
import { operationService } from "../../_services";

function Operations({ match }) {
  const { path } = match;

  return (
    <div className="p-4">
      <div className="container-fluid">
        <Switch>
          <Route exact path={path} component={List} />
          <PrivateRoute
            roles={[Role.Admin, Role.Controller]}
            path={`${path}/import`}
            component={() => (
              <FileUpload
                title="Operation KPIs"
                uploadFile={operationService.uploadFile}
                returnRoute="/operations"
              />
            )}
          />
          <PrivateRoute path={`${path}/add`} component={AddEdit} />
          <PrivateRoute
            roles={[Role.Admin, Role.Controller]}
            path={`${path}/edit/:id`}
            component={AddEdit}
          />
        </Switch>
      </div>
    </div>
  );
}

export { Operations };
