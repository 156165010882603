import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ColumnsService } from "../_services/columns.service";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { filter } from "lodash";

const ColumnsDropdownButton = forwardRef(
  ({ listName, columnsValue, onSetColumns }, ref) => {
    const [columnsList, setColumnsList] = useState([]);
    const [activeColumns, setActiveColumns] = useState(null);

    useEffect(() => {
      ColumnsService.getByList(listName).then((columnss) => {
        setColumnsList(columnss);
      });
    }, [listName]);

    const columnsRef = useRef(null);

    useImperativeHandle(
      ref,
      () => {
        return {
          clearColumns() {
            columnsRef.current.clear();
            setActiveColumns(null);
          },
        };
      },
      []
    );

    const handleChangeColumns = (columns) => {
      if (columns.customOption) {
        if (
          // eslint-disable-next-line no-restricted-globals
          confirm(
            `Sollen die Spalteneinstellungen als '${columns.columnsName}' gespeichert werden?`
          )
        ) {
          ColumnsService.create({
            columnsName: columns.columnsName,
            columnSettings: JSON.stringify(columnsValue),
            list: listName,
          }).then((savedColumns) => {
            setColumnsList((oldList) => {
              const newList = [...oldList];
              newList.push(savedColumns);
              return newList;
            });
          });
        } else {
          setActiveColumns(null);
          columnsRef.current.hideMenu();
          columnsRef.current.blur();
          columnsRef.current.clear();
        }
      } else {
        onSetColumns(JSON.parse(columns.columnSettings));
      }
    };

    const handleDeleteColumns = () => {
      if (!activeColumns) return;
      if (activeColumns.customOption) return;
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(
          `Soll die Spalteneinstellung '${activeColumns.columnsName}' gelöscht werden?`
        )
      )
        return;
      ColumnsService.delete(activeColumns.id).then(() => {
        setColumnsList((oldList) => {
          return filter(oldList, (item) => item.id !== activeColumns.id);
        });
        setActiveColumns(null);
        columnsRef.current.clear();
      });
    };

    const handleSaveColumns = () => {
      if (!activeColumns) return;
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(
          `Sollen die Spalteneinstellungen als '${activeColumns.columnsName}' gespeichert werden?`
        )
      )
        return;
      if (activeColumns.customOption) {
        ColumnsService.create({
          columnsName: activeColumns.columnsName,
          columnSettings: JSON.stringify(columnsValue),
          list: listName,
        }).then((savedColumns) => {
          setColumnsList((oldList) => {
            const newList = [...oldList];
            newList.push(savedColumns);
            return newList;
          });
        });
      } else {
        ColumnsService.update(activeColumns.id, {
          id: activeColumns.id,
          columnsName: activeColumns.columnsName,
          columnSettings: JSON.stringify(columnsValue),
          list: listName,
        }).then((savedColumns) => {
          setColumnsList((oldList) => {
            const newList = filter(
              oldList,
              (item) => item.id !== savedColumns.id
            );
            newList.push(savedColumns);
            return newList;
          });
        });
      }
    };

    return (
      <>
        <button
          type="button"
          id="columnsDropdown"
          className="btn btn-sm btn-secondary dropdown-toggle"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          Meine Spalten
        </button>
        <div className="dropdown-menu p-2" style={{ width: 350 }}>
          <div className="constainer">
            <div className="row justify-content-between">
              <div className="col">
                <Typeahead
                  id="columnsTypeahead"
                  allowNew
                  clearButton={true}
                  labelKey={"columnsName"}
                  newSelectionPrefix="Neue Spalten anlegen: "
                  options={columnsList}
                  placeholder="Spalten auswählen oder neue anlegen..."
                  ref={columnsRef}
                  onChange={(selectedColumns) => {
                    if (selectedColumns.length > 0) {
                      const columns = selectedColumns[0];
                      setActiveColumns(columns);
                      handleChangeColumns(columns);
                    } else setActiveColumns(null);
                  }}
                />
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-3">
                <button
                  className="btn btn-sm btn-primary m-2"
                  onClick={handleSaveColumns}
                  disabled={!activeColumns}
                >
                  Speichern
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-sm btn-danger m-2"
                  onClick={handleDeleteColumns}
                  disabled={!activeColumns || activeColumns.customOption}
                >
                  Löschen
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default ColumnsDropdownButton;
