import { BehaviorSubject } from "rxjs";
import { findIndex, map, uniq } from "lodash";
import { fetchWrapper } from "../_helpers";

export const createService = (baseUrl) => {
  const subject = new BehaviorSubject([]);
  let initialLoad = false;

  function load() {
    return fetchWrapper.get(baseUrl).then((data) => {
      initialLoad = true;
      subject.next(data);
      return data;
    });
  }

  function getById(id) {
    return fetchWrapper.get(`${baseUrl}/${id}`).then((item) => item);
  }

  function getDistinctColumnValues(columnName) {
    if (initialLoad) {
      return uniq(map(subject.value, (item) => item[columnName]));
    } else return [];
  }

  function create(params) {
    return fetchWrapper.post(baseUrl, params).then((item) => {
      const items = subject.value;
      items.push(item);
      subject.next(items);
      return item;
    });
  }

  function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params).then((item) => {
      const items = subject.value;
      const itemIndex = findIndex(items, { id: item.id });
      if (itemIndex > -1) items[itemIndex] = item;
      else items.push(item);
      subject.next(items);
      return item;
    });
  }

  function uploadFile(file, startDate) {
    const body = new FormData();
    body.append("formFile", file);
    body.append("fileName", file.Name);
    body.append("start", startDate);
    return fetchWrapper.postFile(baseUrl, body).then((data) => {
      const currentItems = subject.value;
      const newItems = currentItems.concat(data);
      subject.next(newItems);
    });
  }

  // prefixed with underscore because 'delete' is a reserved word in javascript
  function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`).then((x) => {
      const items = subject.value;
      const itemIndex = findIndex(items, { id: x });
      if (itemIndex > -1) {
        items.splice(itemIndex, 1);
        console.log(items);
        subject.next(items);
      }
      return x;
    });
  }

  return {
    create,
    delete: _delete,
    getDistinctColumnValues,
    getById,
    load,
    subject,
    update,
    uploadFile,
    get value() {
      return subject.value;
    },
    get initialLoad() {
      return initialLoad;
    },
  };
};
