import React from "react";
import { Switch } from "react-router-dom";

import { List } from "./List";
import { erpService } from "../../_services";
import FileUpload from "../../_components/FileUpload";
import { PrivateRoute } from "../../_components";
import { Route } from "react-router-dom";
import { Role } from "../../_helpers";
import { AddEdit } from "./AddEdit";

function Erp({ match }) {
  const { path } = match;

  return (
    <div className="p-4">
      <div className="container-fluid">
        <Switch>
          <Route exact path={path} component={List} />
          <PrivateRoute
            roles={[Role.Admin, Role.Controller]}
            path={`${path}/import`}
            component={() => (
              <FileUpload
                title="P&L"
                uploadFile={erpService.uploadFile}
                returnRoute="/pl"
              />
            )}
          />
          <PrivateRoute
            roles={[Role.Controller, Role.Admin]}
            path={`${path}/edit/:id`}
            component={AddEdit}
          />
        </Switch>
      </div>
    </div>
  );
}

export { Erp };
