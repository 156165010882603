import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { alertService, capexService, selectService } from "../../_services";
import { BaseField } from "../../_components/Form/BaseField";
import { DateField } from "../../_components/Form/DateField";
import { SelectField } from "../../_components/Form/SelectField";

function AddEdit({ history, match }) {
  const id = parseInt(match.params.id);
  const isAddMode = isNaN(id);

  const [initialValues, setInitialValues] = useState({
    idProject: "",
    value: null,
    month: null,
    comment: "",
  });

  useEffect(() => {
    if (!isAddMode) {
      capexService.getById(id).then((capex) =>
        setInitialValues((oldIntialValues) => ({
          ...capex,
        }))
      );
    }
  }, []);

  const validationSchema = Yup.object().shape({
    idProject: Yup.string()
      .required("ID Project darf nicht leer sein.")
      .oneOf(selectService.value.projectIDs, "ID Project existiert nicht."),
    value: Yup.number().nullable(),
    month: Yup.date().nullable(),
    comment: Yup.string().nullable(),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      createCapex(fields, setSubmitting);
    } else {
      updateCapex(id, fields, setSubmitting);
    }
  }

  function createCapex(fields, setSubmitting) {
    capexService
      .create(fields)
      .then(() => {
        alertService.success("Capex erfolgreich angelegt", {
          keepAfterRouteChange: true,
        });
        history.push(".");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  function updateCapex(id, fields, setSubmitting) {
    fields.id = id;
    capexService
      .update(id, fields)
      .then(() => {
        alertService.success("Update erfolgreich", {
          keepAfterRouteChange: true,
        });
        history.push("..");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ errors, touched, isSubmitting, isValid, setFieldValue, values }) => {
        const dateFieldProps = { errors, touched, setFieldValue, values };
        const textFieldProps = { errors, touched };
        const numberFieldProps = { errors, touched, type: "number" };

        return (
          <Form>
            <h1>{isAddMode ? "Capex hinzufügen" : "Capex bearbeiten"}</h1>
            <div className="form-row">
              <SelectField
                fieldName="idProject"
                fieldLabel="ID Project"
                values={selectService.value.projectIDs}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "projectIDs   o")
                }
                {...textFieldProps}
              />
              <BaseField
                fieldName="value"
                fieldLabel="Value"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="orders"
                fieldLabel="Orders"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="invoices"
                fieldLabel="Invoices"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="preorders"
                fieldLabel="Preorders"
                {...numberFieldProps}
              />
              <DateField
                fieldName="month"
                fieldLabel="Month"
                {...dateFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="comment"
                fieldLabel="Comment"
                {...textFieldProps}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-primary"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Speichern
              </button>
              <Link to={isAddMode ? "." : ".."} className="btn btn-link">
                Abbrechen
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export { AddEdit };
