import { BehaviorSubject } from "rxjs";

import { fetchWrapper } from "../_helpers";

const subject = new BehaviorSubject(null);
const baseUrl = `${process.env.REACT_APP_API_SERVER}/selects`;

export const selectService = {
  addValue,
  load,
  subject,
  get value() {
    return subject.value;
  },
};

function load() {
  return fetchWrapper.get(baseUrl).then((data) => {
    subject.next(data);
    return data;
  });
}

function addValue({ value, field }) {
  var newData = { ...subject.value };
  newData[field].push(value);
  subject.next(newData);
}
