import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { alertService, operationService, selectService } from "../../_services";
import { BaseField } from "./../../_components/Form/BaseField";
import { DateField } from "./../../_components/Form/DateField";
import { SelectField } from "../../_components/Form/SelectField";

function AddEdit({ history, match }) {
  const id = parseInt(match.params.id);
  const isAddMode = isNaN(id);

  const [initialValues, setInitialValues] = useState({
    idSite: "",
    idOperation: "",
    operationDate: null,
    sales700bar: null,
    availability700bar: null,
    performance700bar: null,
    successRefuelings700bar: null,
    attempts700bar: null,
    sales350bar: null,
    availability350bar: null,
    performance350bar: null,
    successRefuelings350bar: null,
    attempts350bar: null,
    salesTotal: null,
    salesShare350bar: null,
    operationLoad: null,
    comment: "",
  });

  useEffect(() => {
    if (!isAddMode) {
      operationService.getById(id).then((operation) =>
        setInitialValues((oldIntialValues) => ({
          ...operation,
        }))
      );
    }
  }, []);

  const validationSchema = Yup.object().shape({
    idSite: Yup.string()
      .required("ID Site darf nicht leer sein")
      .oneOf(selectService.value.siteIDs, "ID Site existiert nicht."),
    idOperation: Yup.string().required("Operation ID darf nicht leer sein"),
    operationDate: Yup.date().nullable(),
    sales700bar: Yup.number().nullable(),
    availability700bar: Yup.number().nullable(),
    performance700bar: Yup.number().nullable(),
    successRefuelings700bar: Yup.number().integer().nullable(),
    attempts700bar: Yup.number().integer().nullable(),
    sales350bar: Yup.number().nullable(),
    availability350bar: Yup.number().nullable(),
    performance350bar: Yup.number().nullable(),
    successRefuelings350bar: Yup.number().integer().nullable(),
    attempts350bar: Yup.number().integer().nullable(),
    salesTotal: Yup.number().nullable(),
    salesShare350bar: Yup.number().nullable(),
    load: Yup.number().nullable(),
    comment: Yup.string().nullable(),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      createOperation(fields, setSubmitting);
    } else {
      updateOperation(id, fields, setSubmitting);
    }
  }

  function createOperation(fields, setSubmitting) {
    operationService
      .create(fields)
      .then(() => {
        alertService.success("Operation added successfully", {
          keepAfterRouteChange: true,
        });
        history.push(".");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  function updateOperation(id, fields, setSubmitting) {
    fields.id = id;
    operationService
      .update(id, fields)
      .then(() => {
        alertService.success("Update successful", {
          keepAfterRouteChange: true,
        });
        history.push("..");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ errors, touched, isSubmitting, isValid, setFieldValue, values }) => {
        const dateFieldProps = { errors, touched, setFieldValue, values };
        const textFieldProps = { errors, touched };
        const numberFieldProps = { errors, touched, type: "number" };

        return (
          <Form>
            <h1>
              {isAddMode
                ? "Operation KPI hinzufügen"
                : "Operation KPI bearbeiten"}
            </h1>
            <div className="form-row">
              <SelectField
                fieldName="idSite"
                fieldLabel="ID Site"
                creatable={false}
                values={selectService.value.siteIDs}
                {...textFieldProps}
              />
              <BaseField
                fieldName="idOperation"
                fieldLabel="ID Operation"
                {...textFieldProps}
              />
              <DateField
                fieldName="operationDate"
                fieldLabel="Date"
                {...dateFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="sales700bar"
                fieldLabel="Sales 700bar"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="availability700bar"
                fieldLabel="Availability 700bar"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="performance700bar"
                fieldLabel="Performance 700bar"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="successRefuelings700bar"
                fieldLabel="Successful Refuelings 700bar"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="attempts700bar"
                fieldLabel="Attempts 700bar"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="sales350bar"
                fieldLabel="Sales 350bar"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="availability350bar"
                fieldLabel="Availability 350bar"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="performance350bar"
                fieldLabel="Performance 350bar"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="successRefuelings350bar"
                fieldLabel="Successful Refuelings 350bar"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="attempts350bar"
                fieldLabel="Attempts 350bar"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="salesShare350bar"
                fieldLabel="Sales Share 350bar"
                className="form-group col-md-2"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="salesTotal"
                fieldLabel="Sales Total"
                className="form-group col-md-2"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="load"
                fieldLabel="Load"
                className="form-group col-md-2"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="comment"
                fieldLabel="Comment"
                className="form-group col-md-10 "
                {...textFieldProps}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-primary"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Speichern
              </button>
              <Link to={isAddMode ? "." : ".."} className="btn btn-link">
                Abbrechen
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export { AddEdit };
