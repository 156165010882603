import { filter } from "lodash";
import { createService } from "./base.service";
import { fetchWrapper } from "../_helpers";

export const ubpService = (() => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER}/ubps`;
  const service = createService(baseUrl);
  const getByIdProject = async function (idProject) {
    if (!service.initialLoad) {
      return fetchWrapper.get(`${baseUrl}/idProject/${idProject}`);
    } else {
      const items = service.value;
      return filter(items, { idProject: idProject });
    }
  };
  return {
    ...service,
    getByIdProject,
  };
})();
