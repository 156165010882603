import React from "react";
import "@inovua/reactdatagrid-enterprise/index.css";

import { capexService } from "../../_services/capex.service";
import BaseList, { dateColumnProps } from "../../_components/BaseList";
import { Role } from "../../_helpers";
import { renderEuro, renderFloat } from "../../_components/Form/Utils";

function List({ match }) {
  const columns = [
    {
      name: "id",
      header: "Id",
      defaultFlex: 1,
      defaultVisible: false,
      visible: false,
      hideable: false,
    },
    { name: "idProject", header: "ID Project", defaultFlex: 1 },
    { name: "month", header: "Month", defaultFlex: 1, ...dateColumnProps },
    {
      name: "value",
      header: "Value",
      defaultFlex: 1,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "orders",
      header: "Orders",
      defaultFlex: 1,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "invoices",
      header: "Invoices",
      defaultFlex: 1,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    {
      name: "preorders",
      header: "Preorders",
      defaultFlex: 1,
      type: "number",
      textAlign: "end",
      headerAlign: "start",
      render: renderEuro,
      getEditStartValue: (value) => renderFloat({ value }),
    },
    { name: "comment", header: "Comment", defaultFlex: 1 },
    {
      name: "lastUpdateDate",
      header: "Last Update Date",
      ...dateColumnProps,
      editable: false,
    },
    {
      name: "lastUpdateUser",
      header: "Last Update User",
      editable: false,
    },
  ];

  const filterValue = [
    { name: "idProject", operator: "contains", type: "string", value: "" },
    { name: "month", operator: "after", type: "date", value: "" },
    { name: "value", type: "number" },
    { name: "orders", type: "number" },
    { name: "invoices", type: "number" },
    { name: "preorders", type: "number" },
    { name: "comment", operator: "contains", type: "string", value: "" },
    { name: "lastUpdateDate", operator: "after", type: "date", value: "" },
    { name: "lastUpdateUser", operator: "contains", type: "string", value: "" },
  ];

  return (
    <BaseList
      columns={columns}
      match={match}
      filterValue={filterValue}
      service={capexService}
      storageKey="capex"
      title="CAPEX"
      enableAdd
      enableExport
      entityName="CAPEX"
      idColumn="idProject"
      roles={[Role.Admin, Role.Controller]}
    />
  );
}

export { List };
