export const parseIntInput = (value) =>
  parseInt(value.replace(/\./g, "").replace(",", "."), 10);

export const parseDecimalInput = (value, numberOfDigits = 2) =>
  parseFloat(value.replace(/\./g, "").replace(",", ".")).toFixed(
    numberOfDigits
  );

export const renderEuro = ({ value }) => {
  // eslint-disable-next-line eqeqeq
  if (value || value == 0) {
    return parseFloat(value).toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR",
    });
  } else {
    return "";
  }
};

export const renderFloat = ({ value }) => {
  // eslint-disable-next-line eqeqeq
  if (value || value == 0) {
    return parseFloat(value).toLocaleString("de-DE");
  } else {
    return "";
  }
};

export const renderInt = ({ value }) => {
  // eslint-disable-next-line eqeqeq
  if (value || value == 0) return parseInt(value).toLocaleString("de-DE");
  else return "";
};

export const renderKilo = ({ value }) => {
  // eslint-disable-next-line eqeqeq
  if (value || value == 0)
    return parseFloat(value).toLocaleString("de-DE", {
      style: "unit",
      unit: "kilogram",
      minimumFractionDigits: 1,
    });
  else return "";
};

export const renderPercent = ({ value }) => {
  // eslint-disable-next-line eqeqeq
  if (value || value == 0)
    return parseFloat(value).toLocaleString("de-DE", { style: "percent" });
  else return "";
};
