import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { alertService, selectService, siteService } from "../../_services";
import { BaseField } from "./../../_components/Form/BaseField";
import { DateField } from "./../../_components/Form/DateField";
import { SelectField } from "../../_components/Form/SelectField";

function AddEdit({ history, match }) {
  const id = parseInt(match.params.id);
  const isAddMode = isNaN(id);

  const [initialValues, setInitialValues] = useState({
    idSite: "",
    zip: "",
    city: "",
    street: "",
    federalState: "",
    firstGoLiveDate: null,
    closingDate: null,
    status: "",
    ioc: "",
    ownerHRS: "",
    contractor: "",
    retailAgreementEnd: null,
    h2SupplierGrey: "",
    h2SupplierGreen: "",
    h2SupplierGreenStart: null,
    statusOfSupply: "",
    networkType: "",
    comment: "",
  });

  useEffect(() => {
    if (!isAddMode) {
      siteService.getById(id).then((site) => {
        setInitialValues((oldIntialValues) => ({
          ...site,
        }));
      });
    }
  }, []);

  const validationSchema = Yup.object().shape({
    idSite: Yup.string()
      .required("ID Site darf nicht leer sein")
      .notOneOf(
        isAddMode ? selectService.value.siteIDs : [],
        "ID Site exisitiert bereits."
      ),
    zip: Yup.string().nullable(),
    city: Yup.string().nullable(),
    street: Yup.string().nullable(),
    federalState: Yup.string().nullable(),
    firstGoLiveDate: Yup.date().nullable(),
    closingDate: Yup.date().nullable(),
    status: Yup.string().nullable(),
    ioc: Yup.string().nullable(),
    ownerHRS: Yup.string().nullable(),
    contractor: Yup.string().nullable(),
    retailAgreementEnd: Yup.date().nullable(),
    h2SupplierGrey: Yup.string().nullable(),
    h2SupplierGreen: Yup.string().nullable(),
    h2SupplyGreenStart: Yup.date().nullable(),
    statusOfSupply: Yup.string().nullable(),
    networkType: Yup.string().nullable(),
    comment: Yup.string().nullable(),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      createSite(fields, setSubmitting);
    } else {
      updateSite(id, fields, setSubmitting);
    }
  }

  function createSite(fields, setSubmitting) {
    siteService
      .create(fields)
      .then(() => {
        alertService.success("Site added successfully", {
          keepAfterRouteChange: true,
        });
        history.push(".");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  function updateSite(id, fields, setSubmitting) {
    fields.id = id;
    siteService
      .update(id, fields)
      .then(() => {
        alertService.success("Update successful", {
          keepAfterRouteChange: true,
        });
        history.push("..");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ errors, touched, isSubmitting, isValid, setFieldValue, values }) => {
        const dateFieldProps = { errors, touched, setFieldValue, values };
        const textFieldProps = { errors, touched };
        return (
          <Form>
            <h1>{isAddMode ? "Site hinzufügen" : "Site bearbeiten"}</h1>
            <div className="form-row">
              <BaseField
                fieldName="idSite"
                fieldLabel="ID Site"
                disabled={!isAddMode}
                {...textFieldProps}
              />
              <BaseField fieldName="zip" fieldLabel="Zip" {...textFieldProps} />
              <BaseField
                className="form-group col-6"
                fieldName="city"
                fieldLabel="City"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="street"
                fieldLabel="Street"
                {...textFieldProps}
              />
              <BaseField
                fieldName="federalState"
                fieldLabel="Federal State"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <DateField
                fieldName="firstGoLiveDate"
                fieldLabel="First GoLive Date"
                {...dateFieldProps}
              />
              <DateField
                fieldName="closingDate"
                fieldLabel="Closing Date"
                {...dateFieldProps}
              />
              <SelectField
                fieldName="status"
                fieldLabel="Status"
                values={selectService.value.siteStatus}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "siteStatus")
                }
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <SelectField
                fieldName="ioc"
                fieldLabel="IOC"
                values={selectService.value.siteIOCs}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "siteIOCs")
                }
                {...textFieldProps}
              />
              <BaseField
                fieldName="ownerHRS"
                fieldLabel="Owner HRS"
                {...textFieldProps}
              />
              <BaseField
                fieldName="contractor"
                fieldLabel="Contractor"
                {...textFieldProps}
              />
              <DateField
                fieldName="retailAgreementEnd"
                fieldLabel="Retail Agreement End"
                {...dateFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="h2SupplierGrey"
                fieldLabel="H2 Supplier Grey"
                {...textFieldProps}
              />
              <BaseField
                fieldName="h2SupplierGreen"
                fieldLabel="H2 Supplier Green"
                {...textFieldProps}
              />
              <DateField
                fieldName="h2SupplierGreenStart"
                fieldLabel="H2 Supplier Green Start"
                {...dateFieldProps}
              />
              <BaseField
                fieldName="statusOfSupply"
                fieldLabel="Status of Supply"
                {...textFieldProps}
              />
              <BaseField
                fieldName="networkType"
                fieldLabel="Network Type"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="comment"
                fieldLabel="Comment"
                {...textFieldProps}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-primary"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Speichern
              </button>
              <Link to={isAddMode ? "." : ".."} className="btn btn-link">
                Abbrechen
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export { AddEdit };
