import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { alertService, erpService, selectService } from "../../_services";
import { BaseField } from "./../../_components/Form/BaseField";
import { DateField } from "./../../_components/Form/DateField";
import { SelectField } from "../../_components/Form/SelectField";

function AddEdit({ history, match }) {
  const id = parseInt(match.params.id);
  const isAddMode = isNaN(id);

  const [initialValues, setInitialValues] = useState({
    idSite: "",
    idOperation: "",
    erpOPEXMonth: null,
    erpPurchased: null,
    erpStocklossKg: null,
    erpGrossmarginKg: null,
    erpC2SKg: null,
    erpEBITDAKg: null,
    erpEBITDA: null,
    erpSoldKg: null,
    erpAvgSoldKg: null,
    erpAvgPurchased: null,
    erpRevenues: null,
    erpRebates: null,
    erpCOGS: null,
    erpStockloss: null,
    erpOtherLiquids: null,
    erpGrossProfit: null,
    erpMaintenanceHRS: null,
    erpMaintenanceFacilityManagement: null,
    erpMaintenanceTrailer: null,
    erpRepairHRS: null,
    erpRepairCooling: null,
    erpRepairCompressor: null,
    erpRepairTrailer: null,
    erpInspectionTUEV: null,
    erpInspectionQuality: null,
    erpInspectionMetering: null,
    erpEnergy: null,
    erpRetailerCompensationOPEX: null,
    erpRetailerCompensationLease: null,
    erpDataTransmission: null,
    erpRent: null,
    erpMonitoring: null,
    erpPayment: null,
    erpTransport: null,
    erpOtherOPEX: null,
    erpOperationalIncome: null,
    erpIT: null,
    erpManpower: null,
    erpOVHOther: null,
    erpDepreciation: null,
    erpOtherIncome: null,
    erpSiteEBIT: null,
    comment: "",
  });

  useEffect(() => {
    if (!isAddMode) {
      erpService.getById(id).then((operation) =>
        setInitialValues((oldIntialValues) => ({
          ...operation,
        }))
      );
    }
  }, []);

  const validationSchema = Yup.object().shape({
    idSite: Yup.string()
      .required("ID Site darf nicht leer sein.")
      .oneOf(selectService.value.siteIDs, "ID Site existiert nicht."),
    erpOPEXMonth: Yup.date().nullable(),
    erpPurchased: Yup.number().nullable(),
    erpStocklossKg: Yup.number().nullable(),
    erpGrossmarginKg: Yup.number().nullable(),
    erpC2SKg: Yup.number().nullable(),
    erpEBITDAKg: Yup.number().nullable(),
    erpEBITDA: Yup.number().nullable(),
    erpSoldKg: Yup.number().nullable(),
    erpAvgSoldKg: Yup.number().nullable(),
    erpAvgPurchased: Yup.number().nullable(),
    erpRevenues: Yup.number().nullable(),
    erpRebates: Yup.number().nullable(),
    erpCOGS: Yup.number().nullable(),
    erpStockloss: Yup.number().nullable(),
    erpOtherLiquids: Yup.number().nullable(),
    erpGrossProfit: Yup.number().nullable(),
    erpMaintenanceHRS: Yup.number().nullable(),
    erpMaintenanceFacilityManagement: Yup.number().nullable(),
    erpMaintenanceTrailer: Yup.number().nullable(),
    erpRepairHRS: Yup.number().nullable(),
    erpRepairCooling: Yup.number().nullable(),
    erpRepairCompressor: Yup.number().nullable(),
    erpRepairTrailer: Yup.number().nullable(),
    erpInspectionTUEV: Yup.number().nullable(),
    erpInspectionQuality: Yup.number().nullable(),
    erpInspectionMetering: Yup.number().nullable(),
    erpEnergy: Yup.number().nullable(),
    erpRetailerCompensationOPEX: Yup.number().nullable(),
    erpRetailerCompensationLease: Yup.number().nullable(),
    erpDataTransmission: Yup.number().nullable(),
    erpRent: Yup.number().nullable(),
    erpMonitoring: Yup.number().nullable(),
    erpPayment: Yup.number().nullable(),
    erpTransport: Yup.number().nullable(),
    erpOtherOPEX: Yup.number().nullable(),
    erpOperationalIncome: Yup.number().nullable(),
    erpIT: Yup.number().nullable(),
    erpManpower: Yup.number().nullable(),
    erpOVHOther: Yup.number().nullable(),
    erpDepreciation: Yup.number().nullable(),
    erpOtherIncome: Yup.number().nullable(),
    erpSiteEBIT: Yup.number().nullable(),
    comment: Yup.string().nullable(),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      createERP(fields, setSubmitting);
    } else {
      updateERP(id, fields, setSubmitting);
    }
  }

  function createERP(fields, setSubmitting) {
    erpService
      .create(fields)
      .then(() => {
        alertService.success("P&L added successfully", {
          keepAfterRouteChange: true,
        });
        history.push(".");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  function updateERP(id, fields, setSubmitting) {
    fields.id = id;
    erpService
      .update(id, fields)
      .then(() => {
        alertService.success("Update successful", {
          keepAfterRouteChange: true,
        });
        history.push("..");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ errors, touched, isSubmitting, isValid, setFieldValue, values }) => {
        const dateFieldProps = { errors, touched, setFieldValue, values };
        const textFieldProps = { errors, touched };
        const numberFieldProps = { errors, touched, type: "number" };

        return (
          <Form>
            <h1>{isAddMode ? "P&L hinzufügen" : "P&L bearbeiten"}</h1>
            <div className="form-row">
              <SelectField
                fieldName="idSite"
                fieldLabel="ID Site"
                creatable={false}
                values={selectService.value.siteIDs}
                {...textFieldProps}
              />
              <DateField
                fieldName="erpOpexMonth"
                fieldLabel="OPEX Month"
                {...dateFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="erpPurchased"
                fieldLabel="Purchased"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpStocklossKg"
                fieldLabel="Stockloss Kg"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpGrossmarginKg"
                fieldLabel="Grossmargin €/Kg"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpC2SKg"
                fieldLabel="Cost-to-serve €/Kg"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="erpEBITDAKg"
                fieldLabel="EBITDA €/Kg"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpEBITDA"
                fieldLabel="Site EBITDA"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpSoldKg"
                fieldLabel="Sold Kg"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpAvgSoldKg"
                fieldLabel="Avg. Sold H2 €/Kg"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpAvgPurchased"
                fieldLabel="Avg. Purch. H2 €/Kg"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="erpRevenues"
                fieldLabel="Revenues"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpRebates"
                fieldLabel="Rebates"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpCOGS"
                fieldLabel="COGS"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpStockloss"
                fieldLabel="Stockloss"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="erpOtherLiquids"
                fieldLabel="Other Liquids"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpGrossProfit"
                fieldLabel="Gross Profit"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpMaintenanceHRS"
                fieldLabel="Maintenance HRS"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpMaintenanceFacilityManagement"
                fieldLabel="Maintenance Facility Management"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="erpMaintenanceTrailer"
                fieldLabel="Maintenance Trailer"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpRepairHRS"
                fieldLabel="Repair HRS"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpRepairCooling"
                fieldLabel="Repair Cooling"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpRepairCompressor"
                fieldLabel="Repair Compressor"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="erpRepairTrailer"
                fieldLabel="Repair Trailer"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpInspectionTUEV"
                fieldLabel="Inspection TUEV"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpInspectionQuality"
                fieldLabel="Inspection Quality"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpInspectionMetering"
                fieldLabel="Inspection Quality"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="erpEnergy"
                fieldLabel="Energy"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpRetailerCompensationOPEX"
                fieldLabel="Retailer Compensation OPEX"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpRetailerCompensationLease"
                fieldLabel="Retailer Compensation Lease"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpDataTransmission"
                fieldLabel="Data Transmission"
                {...numberFieldProps}
              />
            </div>

            <div className="form-row">
              <BaseField
                fieldName="erpRent"
                fieldLabel="Rent"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpMonitoring"
                fieldLabel="Monitoring"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpPayment"
                fieldLabel="Payment"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpTransport"
                fieldLabel="Transport"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="erpOtherOPEX"
                fieldLabel="Other Opex"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpOperationalIncome"
                fieldLabel="Operational Income"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpIT"
                fieldLabel="IT"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpManpower"
                fieldLabel="Manpower"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpOVHOther"
                fieldLabel="OVH Other"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="erpDepreciation"
                fieldLabel="Depreciation"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpOtherIncome"
                fieldLabel="Other Income"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="erpSiteEBIT"
                fieldLabel="Site EBIT"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="comment"
                fieldLabel="Comment"
                className="form-group col-md-10 "
                {...textFieldProps}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-primary"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Speichern
              </button>
              <Link to={isAddMode ? "." : ".."} className="btn btn-link">
                Abbrechen
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export { AddEdit };
