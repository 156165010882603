import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { alertService, selectService } from "../../_services";
import { fundingService } from "../../_services/funding.service";
import { projectService } from "../../_services/project.service";
import { BaseField } from "../../_components/Form/BaseField";
import { DateField } from "../../_components/Form/DateField";
import { map, remove } from "lodash";
import { SelectField } from "../../_components/Form/SelectField";

function AddEdit({ history, match }) {
  const id = parseInt(match.params.id);
  const isAddMode = isNaN(id);

  const [initialValues, setInitialValues] = useState({
    idFunding: "",
    projectStatus: "",
    call: "",
    sign: "",
    budget: null,
    quota: null,
    start: null,
    end: null,
    contact1: "",
    contact2: "",
    contact3: "",
    appropriation: "",
    appropriationDeadline: null,
    comment: "",
    cashflow: null,
  });

  let signs = selectService.value.signs;

  useEffect(() => {
    if (!isAddMode) {
      fundingService.getById(id).then((funding) => {
        setInitialValues((oldIntialValues) => ({
          ...oldIntialValues,
          ...funding,
        }));
        if (funding.sign) signs = remove(signs, (x) => x == funding.sign);
      });
    }
  }, []);

  const validationSchema = Yup.object().shape({
    idFunding: Yup.string().required("ID Funding darf nicht leer sein."),
    projectStatus: Yup.string().nullable(),
    call: Yup.string().nullable(),
    sign: Yup.string().nullable().notOneOf(signs),
    budget: Yup.number().nullable(),
    quota: Yup.number().nullable(),
    start: Yup.date().nullable(),
    end: Yup.date().nullable(),
    contact1: Yup.string().nullable(),
    contact2: Yup.string().nullable(),
    contact3: Yup.string().nullable(),
    appropriation: Yup.string().nullable(),
    appropriationDeadline: Yup.date().nullable(),
    comment: Yup.string().nullable(),
    cashflow: Yup.string().nullable(),
  });

  function onSubmit(fields, { setStatus, setSubmitting }) {
    setStatus();
    if (isAddMode) {
      createFunding(fields, setSubmitting);
    } else {
      updateFunding(id, fields, setSubmitting);
    }
  }

  function createFunding(fields, setSubmitting) {
    fundingService
      .create(fields)
      .then(() => {
        alertService.success("Funding erfolgreich angelegt", {
          keepAfterRouteChange: true,
        });
        history.push(".");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  function updateFunding(id, fields, setSubmitting) {
    fields.id = id;
    fundingService
      .update(id, fields)
      .then(() => {
        alertService.success("Update erfolgreich", {
          keepAfterRouteChange: true,
        });
        history.push("..");
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ errors, touched, isSubmitting, isValid, setFieldValue, values }) => {
        const dateFieldProps = { errors, touched, setFieldValue, values };
        const textFieldProps = { errors, touched };
        const numberFieldProps = { errors, touched, type: "number" };

        return (
          <Form>
            <h1>{isAddMode ? "Funding hinzufügen" : "Funding bearbeiten"}</h1>
            <div className="form-row">
              <BaseField
                fieldName="idFunding"
                fieldLabel="ID Funding"
                {...textFieldProps}
              />
              <SelectField
                fieldName="projectStatus"
                fieldLabel="Project Status"
                values={selectService.value.fundingProjectStatus}
                onCreateOption={(newOption) =>
                  selectService.addValue(newOption, "fundingProjectStatus")
                }
                {...textFieldProps}
              />
              <BaseField
                fieldName="call"
                fieldLabel="Call"
                {...textFieldProps}
              />
              <BaseField
                fieldName="sign"
                fieldLabel="Sign"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="budget"
                fieldLabel="Budget"
                {...numberFieldProps}
              />
              <BaseField
                fieldName="quota"
                fieldLabel="Quota"
                {...numberFieldProps}
              />
              <DateField
                fieldName="start"
                fieldLabel="Start"
                {...dateFieldProps}
              />
              <DateField fieldName="end" fieldLabel="End" {...dateFieldProps} />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="contact1"
                fieldLabel="Contact1"
                {...textFieldProps}
              />
              <BaseField
                fieldName="contact2"
                fieldLabel="Contact2"
                {...textFieldProps}
              />
              <BaseField
                fieldName="contact3"
                fieldLabel="Contact3"
                {...textFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="appropriation"
                fieldLabel="Appropriation"
                {...textFieldProps}
              />
              <DateField
                fieldName="appropriationDeadline"
                fieldLabel="Appropriation Deadline"
                {...dateFieldProps}
              />
              <BaseField
                fieldName="cashflow"
                fieldLabel="Cashflow"
                {...numberFieldProps}
              />
            </div>
            <div className="form-row">
              <BaseField
                fieldName="comment"
                fieldLabel="Comment"
                {...textFieldProps}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className="btn btn-primary"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Speichern
              </button>
              <Link to={isAddMode ? "." : ".."} className="btn btn-link">
                Abbrechen
              </Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export { AddEdit };
