import React, { useEffect, useState } from "react";
import { Role } from "../../_helpers";
import { accountService, siteService } from "../../_services";
import { defaultTo, get } from "lodash";
import ProjectList from "./ProjectList";
import ProjectDashboard from "./ProjectDashboard";
import { toDateString } from "../../_helpers/utils";
import OperationDashboard from "./OperationDashboard";
import ErpDashboard from "./ErpDashboard";
import "./dashboard.css";

const SiteDashboard = ({ id }) => {
  const [site, setSite] = useState(null);
  const [project, setProject] = useState(null);

  useEffect(() => {
    siteService.getById(id).then((siteData) => {
      setSite(siteData);
    });
    setProject(null);
  }, [id]);

  if (!site) return <></>;

  const userRole = accountService.userValue.role;
  const showFinancials = Role[userRole] !== Role.ReadOnlyLimited;

  return (
    <div className="container-fluid">
      <div className="row" style={{ marginBottom: "10px" }}>
        <div className="col-3">
          <h3>{`${site.idSite} ${site.city}`}</h3>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <td colSpan="2">
                  <h4 style={{ marginBottom: "0" }}>Site Overview</h4>
                </td>
              </tr>
              <tr>
                <td width={220}></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colSpan="2">Status</th>
              </tr>
              <tr>
                <td>First GoLive Date</td>
                <td>{toDateString(site.firstGoLiveDate)}</td>
              </tr>
              <tr>
                <td>Closing Date</td>
                <td>{toDateString(site.closingDate)}</td>
              </tr>
              <tr>
                <td>Status</td>
                <td>{site.status}</td>
              </tr>
              <tr>
                <td>Network Type</td>
                <td>{site.networkType}</td>
              </tr>
              <tr>
                <th colSpan="2">Site property</th>
              </tr>
              <tr>
                <td>IOC</td>
                <td>{site.ioc}</td>
              </tr>
              <tr>
                <td>Owner HRS</td>
                <td>{site.ownerHRS}</td>
              </tr>
              <tr>
                <td>Site Contractor</td>
                <td>{site.contractor}</td>
              </tr>
              <tr>
                <td>Retail/Lease Agreement End</td>
                <td>{toDateString(site.retailAgreementEnd)}</td>
              </tr>
              <tr>
                <th colSpan="2">H2 Supply</th>
              </tr>
              <tr>
                <td>H2 Supplier Grey</td>
                <td>{site.h2SupplierGrey}</td>
              </tr>
              <tr>
                <td>H2 Supplier Green</td>
                <td>{site.h2SupplierGreen}</td>
              </tr>
              <tr>
                <td>H2 Supply Green Start</td>
                <td>{toDateString(site.h2SupplyGreenStart)}</td>
              </tr>
              <tr>
                <td colSpan="2">Comment</td>
              </tr>
              <tr>
                <td colSpan="2" style={{ backgroundColor: "#e1e1e1" }}>
                  {site.comment}
                </td>
              </tr>
              <tr>
                <td className="update">Last Update</td>
                <td className="update">
                  {toDateString(site.lastUpdateDate, {
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </td>
              </tr>
              <tr>
                <td className="update">Updated by</td>
                <td className="update">{site.lastUpdateUser}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-9">
          <h3>{defaultTo(site.street, "-")}</h3>
          <h4>Projects</h4>
          <ProjectList
            idSite={get(site, "idSite")}
            onSelectProject={(selectedProject) => {
              setProject(selectedProject);
            }}
          />
          {project && <ProjectDashboard project={project} />}
        </div>
      </div>
      <div className="row" style={{ marginBottom: "10px" }}>
        <div className="col-12">
          {site && <OperationDashboard idSite={site.idSite} />}
        </div>
      </div>
      {showFinancials && (
        <div className="row">
          <div className="col-12">
            {site && <ErpDashboard idSite={site.idSite} />}
          </div>
        </div>
      )}
    </div>
  );
};

export default SiteDashboard;
