import React, { useEffect, useState } from "react";
import { erpService } from "../../_services/erp.service";
import { toDateString } from "./../../_helpers/utils";
import { defaultTo, orderBy } from "lodash";

const ErpDashboard = ({ idSite }) => {
  const [erps, setErps] = useState([]);

  useEffect(() => {
    erpService
      .getByIdSite(idSite)
      .then((erpData) => setErps(orderBy(erpData, "erpOPEXMonth")));
  }, [idSite]);

  return (
    <div className="tableWrap" style={{ overflowX: "auto" }}>
      <table className="kpi stickFirstColumn">
        <thead>
          <tr>
            <td colSpan="36">
              <h4 style={{ marginBottom: "0" }}>Financial KPIs</h4>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>
            <th className="main" colSpan="4">
              Main KPIs
            </th>
            <th colSpan="6">Gross margin - H2 sales</th>
            <th colSpan="9">OPEX</th>
            <th colSpan="4">Summen</th>
          </tr>
          <tr>
            <th>Month</th>
            <th className="main">
              Gross Margin H2 sales incl. losses [&#x20AC;/kg]
            </th>
            <th className="main">Cost-to-serve [&#x20AC;/kg]</th>
            <th className="main">EBITDA [&#x20AC;/kg]</th>
            <th className="main">EBITDA [&#x20AC;]</th>
            <th>Sold H2 [kg]</th>
            <th>Avg. Sold H2 [&#x20AC;/kg]</th>
            <th>Avg. Purch. H2 [&#x20AC;/kg]</th>
            <th>Stockloss [kg]</th>
            <th>Stockloss [%]</th>
            <th>Real Gross Margin</th>
            <th>Maintenance</th>
            <th>Repair</th>
            <th>Energy</th>
            <th>Energy [kWh/kg]</th>
            <th>Transport Trailer</th>
            <th>Lease / RC</th>
            <th>Inspection</th>
            <th>Supervisor</th>
            <th>Other OPEX</th>
            <th>Operational income</th>
            <th>Overhead</th>
            <th>Depreciation</th>
            <th>Site EBIT</th>
          </tr>
          {erps.map((erp) => {
            return (
              <tr key={erp.erpOPEXMonth}>
                <td>
                  {toDateString(erp.erpOPEXMonth, {
                    year: "numeric",
                    month: "short",
                  })}
                </td>
                <td>
                  {Number(erp.erpGrossmarginKg).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {Number(erp.erpC2SKg).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {Number(erp.erpEBITDAKg).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {Number(erp.erpEBITDA).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpSoldKg).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpAvgSoldKg).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {Number(erp.erpAvgPurchased).toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {Number(erp.erpStocklossKg).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpStockloss).toLocaleString("de-DE", {
                    style: "percent",
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpRealGrossmargin).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpMaintenance).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpRepair).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpEnergy).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpEnergyKg).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpTransport).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpRetailerCompensationLease).toLocaleString(
                    "de-DE",
                    {
                      maximumFractionDigits: 0,
                    }
                  )}
                </td>
                <td>
                  {Number(erp.erpInspection).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpSupervisor).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpOtherOPEX).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpOperationalIncome).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpOverhead).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpDepreciation).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
                <td>
                  {Number(erp.erpSiteEBIT).toLocaleString("de-DE", {
                    maximumFractionDigits: 0,
                  })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ErpDashboard;
