import { filter, first } from "lodash";
import { createService } from "./base.service";
import { fetchWrapper } from "../_helpers";

export const fundingService = (() => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER}/fundings`;
  const service = createService(baseUrl);
  const getByIdProject = async function (idProject) {
    return fetchWrapper.get(`${baseUrl}/idProject/${idProject}`);
  };
  return {
    ...service,
    getByIdProject,
  };
})();
