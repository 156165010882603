import React from "react";
import { Link } from "react-router-dom";

import { accountService } from "../_services";

function Details({ match }) {
  const { path } = match;
  const user = accountService.userValue;

  return (
    <div>
      <h1>Mein Profil</h1>
      <p>
        <strong>Name: </strong> {user.firstName} {user.lastName}
        <br />
        <strong>Email: </strong> {user.email}
      </p>
      <p>
        <Link to={`${path}/update`}>Profil bearbeiten</Link>
      </p>
    </div>
  );
}

export { Details };
