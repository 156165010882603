import { BehaviorSubject } from "rxjs";
import { findIndex } from "lodash";
import { fetchWrapper } from "../_helpers";

export const FilterService = (() => {
  const baseUrl = `${process.env.REACT_APP_API_SERVER}/filters`;
  const subject = new BehaviorSubject([]);

  function create(params) {
    return fetchWrapper.post(baseUrl, params).then((item) => {
      const items = subject.value;
      items.push(item);
      subject.next(items);
      return item;
    });
  }

  function update(id, params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params).then((item) => {
      const items = subject.value;
      const itemIndex = findIndex(items, { id: item.id });
      if (itemIndex > -1) items[itemIndex] = item;
      else items.push(item);
      subject.next(items);
      return item;
    });
  }

  // prefixed with underscore because 'delete' is a reserved word in javascript
  function _delete(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`).then((x) => {
      const items = subject.value;
      const itemIndex = findIndex(items, { id: x });
      if (itemIndex > -1) {
        items.splice(itemIndex, 1);
        console.log(items);
        subject.next(items);
      }
      return x;
    });
  }

  function getByList(list) {
    return fetchWrapper.get(`${baseUrl}/list/${list}`).then((items) => items);
  }

  return {
    create,
    update,
    delete: _delete,
    getByList,
    subject,
    get value() {
      return subject.value;
    },
  };
})();
