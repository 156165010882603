import { isNull, isUndefined } from "lodash";

export const toDateString = (date, options) => {
  if (isNull(date)) return "";
  if (!options) {
    options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
  }
  return new Date(date).toLocaleDateString("default", {
    ...options,
  });
};

let kiloFormat = null;
export const toKilogram = (number, digits) => {
  if (!kiloFormat) {
    kiloFormat = new Intl.NumberFormat(undefined, {
      style: "unit",
      unit: "kilogram",
      maximumFractionDigits: digits,
    });
  }
  return kiloFormat.format(number);
};

let percentFormat = null;
export const toPercent = (number, options) => {
  if (!percentFormat) {
    percentFormat = new Intl.NumberFormat(undefined, { style: "percent" });
  }
  if (isUndefined(options)) {
    return percentFormat.format(number);
  }
  return new Intl.NumberFormat(undefined, {
    style: "percent",
    ...options,
  }).format(number);
};
