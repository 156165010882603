import React from "react";
import { Route, Switch } from "react-router-dom";

import { List } from "./List";
import { AddEdit } from "./AddEdit";
import { PrivateRoute } from "../../_components";
import { Role } from "../../_helpers";

function Projects({ match }) {
  const { path } = match;

  return (
    <div className="p-4">
      <div className="container-fluid">
        <Switch>
          <Route exact path={path} component={List} />
          <PrivateRoute
            roles={[Role.FM, Role.PM_PD, Role.Controller, Role.Admin]}
            path={`${path}/add`}
            component={AddEdit}
          />
          <PrivateRoute
            roles={[Role.FM, Role.PM_PD, Role.Controller, Role.Admin]}
            path={`${path}/edit/:id`}
            component={AddEdit}
          />
        </Switch>
      </div>
    </div>
  );
}

export { Projects };
